import { MAIN_CONTENT_ID } from '@/components/accessibility/SkipToMain';
import HeaderAuthModal from '@/components/homepageRefresh/header/HeaderAuthModal';
import AuthModalContextProvider from '@/context/AuthModalContextProvider';
import { BlogPost } from '@/utils/blogPosts';

import BlogFeed from '../BlogFeed';
import FAQ from '../FAQ';
import Hero from '../Hero';
import HomepageFAQ from '../HomepageLayout/data/faq.mdx';
import PlanToggle from '../PlanToggle';
import Testimonials from '../Testimonials';
import ValueProps from '../ValueProps';
import { messages as blogMessages } from './data/blog';
import { logos } from './data/logos';
import { reviews, messages as reviewsMessages } from './data/reviews';
import { GradientBg, Wrapper } from './styles';

const HomepageLayout = ({ blogPosts }: { blogPosts: BlogPost[] }) => {
	return (
		<Wrapper id={MAIN_CONTENT_ID}>
			<AuthModalContextProvider>
				<Hero />
				<HeaderAuthModal />
			</AuthModalContextProvider>
			<ValueProps />
			<GradientBg>
				<Testimonials logos={logos} reviews={reviews} reviewsHeading={reviewsMessages.reviewsHeading} />
			</GradientBg>
			<PlanToggle backgroundUrl="/_n/images/homepage/blue-wave-bg.webp" fadeBg={false} />
			<GradientBg>
				<BlogFeed blogPosts={blogPosts} heading={blogMessages.heading} />
				<FAQ FaqSections={HomepageFAQ} />
			</GradientBg>
		</Wrapper>
	);
};

export default HomepageLayout;
